<template>
  <v-card outlined>
    <v-card-title>
      {{ currentRouteName }}

      <v-spacer></v-spacer>
      <v-btn depressed color="info" to="/location/manage">
        add new location
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <v-card-text>
              <v-data-table
                v-if="tab == 0"
                key="tab_datatable_suppliers_1"
                :headers="headers"
                :items="locations"
              ></v-data-table>
              <v-container
                v-if="tab == 1 && currentUserType.key != 'tenant_user'"
              >
                <v-select
                  @change="onTenantChange"
                  hide-details=""
                  :items="tenants"
                  item-text="name"
                  item-value="id"
                  label="Select Tenant"
                  outlined
                ></v-select>
              </v-container>
              <v-data-table
                v-if="tab == 1"
                key="tab_datatable_suppliers_2"
                :headers="headersbyTenant"
                :items.sync="locationsByTenant"
              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: null,
      items: ["Locations Distribuye", "Locations Inventory"],
      headersbyTenant: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "ID",
          value: "id",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Name",
          value: "name",
        },
      ],
      headers: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Location",
          value: "locationAddress",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Zip",
          value: "locationZipcode",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Número de catastro",
          value: "locationCatastro",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "City",
          value: "locationCity",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "locations",
      "tenants",
      "currentUserType",
      "locationsByTenant",
      "currentTenant",
    ]),
    currentRouteName() {
      return this.$route.name;
    },
    distribuyeLocations() {
      const self = this;
      if (self.locations && !self.locations) return [];
      return self.locations.distribuye;
    },
    inventoryLocations() {
      const self = this;

      if (!self.locations.inventory) return [];

      return self.locations.inventory;
    },
  },
  methods: {
    ...mapActions(["getAllLocations", "getAllTenants", "getLocationsByTenant"]),
  },
  async created() {
    const self = this;
    await self.getAllLocations();
    await self.getAllTenants();
    if (self.currentUserType.key == "tenant_user") {
      self.getLocationsByTenant(self.currentTenant.id);
    }
  },
};
</script>

<style>
</style>